.password-strength-meter {
    text-align: left;
}

.password-strength-meter-progress {
    -webkit-appearance: none;
    -moz-appearance: none;
         appearance: none;
    width: 250px;
    height: 8px;
}

.password-strength-meter-progress::-webkit-progress-bar {
    background-color: #eee;
    border-radius: 3px;
}

.password-strength-meter-label {
    font-size: 14px;
}

.password-strength-meter-progress::-webkit-progress-value {
    border-radius: 2px;
    background-size: 35px 20px, 100% 100%, 100% 100%;
}

.strength-Weak::-webkit-progress-value {
    background-color: #F25F5C;
}
.strength-Fair::-webkit-progress-value {
    background-color: #FFE066;
}
.strength-Good::-webkit-progress-value {
    background-color: #247BA0;
}
.strength-Strong::-webkit-progress-value {
    background-color: #70C1B3;
}
* {
    margin: 0;
}
html, body {
    height: 100%;
}
body { font-family: oswald; font-size: large; background-color: #282828; }

/*
background-image: url('bg-small.jpg'); background-attachment: fixed;
*/
.nowrap {
    white-space: nowrap;
}
.content {
    min-height: 100%;
    height: 100%;
    margin: 0 auto -155px; /* the bottom margin is the negative value of the footer's height */
    margin-bottom: 100px;
}
.page-footer {
    position: fixed;
    width: 100%;
    bottom: 10px;
    height: 50px; /* .push must be the same height as .footer */
}
@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.sigCanvas {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.bg-cart {
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    color: #fff!important;
    background-color: #dc3545;
    background-image: linear-gradient(45deg, black 25%, transparent 25.5%, transparent 50%, black 50.5%, black 75%, transparent 75.5%, transparent);
    background-size:  50px 50px;
}

.bg-pending {
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    color: #fff!important;
    background-color: #ffc107;
    background-image: linear-gradient(45deg, black 25%, transparent 25.5%, transparent 50%, black 50.5%, black 75%, transparent 75.5%, transparent);
    background-size:  50px 50px;
}

.logoSpin {
    animation:spin 4s linear infinite;
}
@keyframes spin { 100% { transform:rotate(360deg); } }

@media screen {
    .onlyprint { display: none; }
}

@media print {
    #RoomChargeReport nav { display: none; }
    .noprint { display: none; }
}

.units { font-family: oswald; font-size: larger; }
.oswald { font-family: oswald; }
.form-inline .form-group { display: block; }

.sigCanvas {
    border: 2px dashed #ccc;
    border-radius: 10px;
    max-width: 100%;
}

.DragDropArea
{
    width: 100%;
    display: inline-block;
    position: relative;
    margin-bottom: 10px;
}
.cursorPointer
{
    cursor: pointer;
}
.photoBox
{
    display: block;
    overflow:hidden;
    max-width: 200px;
    max-height: 150px;
    min-width: 200px;
    min-height: 150px;
    border: 2px solid #ccc;
    border-radius: 5px;
    margin: 5px;
}

h1, h2 ,h3 ,h4, h5, .btn {
    text-transform: uppercase;
    font-family: oswald;
}

.input-group>.custom-select:not(:first-child), .input-group>.form-control:not(:first-child) {
    height: 60px;
}
.input-group {
    height: 60px;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #fff;
    background-color: #343a40!important;
    border-color: #dee2e6 #dee2e6 #fff;
    border-bottom: 0px solid #000;
}

.nav-tabs .nav-link {
    border: 1px solid rgba(0,0,0,.125);
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    border-bottom: 0px solid #000;
}

.nav-tabs {
    border-bottom: 1px solid transparent;
}

.bg-success.inCart {
    background: repeating-linear-gradient(
        45deg,
        #28a746,
        #28a746 10px,
        #6c757d 10px,
        #6c757d 20px
    );
}

.bg-danger.inCart {
    background: repeating-linear-gradient(
        45deg,
        #dc3545,
        #dc3545 10px,
        #6c757d 10px,
        #6c757d 20px
    );
}

.bg-warning.inCart {
    background: repeating-linear-gradient(
        45deg,
        #ffc106,
        #ffc106 10px,
        #6c757d 10px,
        #6c757d 20px
    );
}

.bg-primary.inCart {
    background: repeating-linear-gradient(
        45deg,
        #057bff,
        #057bff 10px,
        #6c757d 10px,
        #6c757d 20px
    );
}

.bg-dark.inCart {
    background: repeating-linear-gradient(
        45deg,
        #353a40,
        #353a40 10px,
        #6c757d 10px,
        #6c757d 20px
    );
}


